import React, { memo, forwardRef, ButtonHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import { BUTTON_VARIANT, ButtonVariant } from "./button-variants";
import * as Styled from "./Button.style";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isDisabled?: boolean;
  variant?: ButtonVariant;
  children: React.ReactNode;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { children, isDisabled = false, variant = BUTTON_VARIANT.ACCENT, type = "button", ...delegated },
  ref
) {
  const { i18n } = useTranslation();

  return (
    <Styled.Button lang={i18n.language} type={type} variant={variant} disabled={isDisabled} ref={ref} {...delegated}>
      {children}
    </Styled.Button>
  );
});

export default memo(Button);
