export const TOS = "<!DOCTYPE html>\n" +
  "<html lang=\"en-US\">\n" +
  "\t<head>\n" +
  "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n" +
  "\t\t<meta charset=\"utf-8\" />\n" +
  "\t\t<title>\n" +
  "\t\t</title>\n" +
  "\t\t<style>\n" +
  "\t\t\tbody { text-align:right; line-height:110%; font-family:Calibri; font-size:10.5pt }\n" +
  "\t\t\th1, h2, h3, h4, h5, h6, p { margin:0pt 0pt 6pt }\n" +
  "\t\t\tli { margin-top:0pt; margin-bottom:6pt }\n" +
  "\t\t\th1 { margin-top:20pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:normal; border-bottom:0.75pt solid #4472c4; padding-bottom:1pt; font-family:'Calibri Light'; font-size:18pt; font-weight:normal; color:#2f5496 }\n" +
  "\t\t\th2 { margin-top:8pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:normal; font-family:'Calibri Light'; font-size:14pt; font-weight:normal; color:#2f5496 }\n" +
  "\t\t\th3 { margin-top:4pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:normal; font-family:'Calibri Light'; font-size:13pt; font-weight:normal; color:#404040 }\n" +
  "\t\t\th4 { margin-top:4pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:110%; font-family:'Calibri Light'; font-size:12pt; font-weight:normal; font-style:normal; color:#000000 }\n" +
  "\t\t\th5 { margin-top:4pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:110%; font-family:'Calibri Light'; font-size:11pt; font-weight:normal; font-style:italic; color:#000000 }\n" +
  "\t\t\th6 { margin-top:4pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:110%; font-family:'Calibri Light'; font-size:10.5pt; font-weight:normal; color:#595959 }\n" +
  "\t\t\t.Heading7 { margin-top:4pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:110%; font-family:'Calibri Light'; font-size:10.5pt; font-weight:normal; font-style:italic; color:#595959 }\n" +
  "\t\t\t.Heading8 { margin-top:4pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:110%; font-family:'Calibri Light'; font-size:10.5pt; font-weight:normal; font-variant:small-caps; color:#595959 }\n" +
  "\t\t\t.Heading9 { margin-top:4pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; font:italic small-caps normal 10.5pt/110% 'Calibri Light'; color:#595959 }\n" +
  "\t\t\t.BalloonText { margin-bottom:0pt; line-height:normal; font-family:'Segoe UI'; font-size:9pt }\n" +
  "\t\t\t.Caption { margin-bottom:6pt; line-height:normal; font-size:10pt; font-weight:bold; color:#404040 }\n" +
  "\t\t\t.CommentSubject { margin-bottom:6pt; line-height:normal; font-size:10pt; font-weight:bold }\n" +
  "\t\t\t.CommentText { margin-bottom:6pt; line-height:normal; font-size:10pt }\n" +
  "\t\t\t.IntenseQuote { margin:5pt 43.2pt 12pt; text-align:center; line-height:110%; font-family:'Calibri Light'; font-size:14pt; color:#4472c4 }\n" +
  "\t\t\t.ListParagraph { margin-right:36pt; margin-bottom:6pt; line-height:110%; font-size:10.5pt }\n" +
  "\t\t\t.NoSpacing { margin-bottom:0pt; line-height:normal; font-size:10.5pt }\n" +
  "\t\t\t.NormalWeb { margin-top:5pt; margin-bottom:5pt; line-height:normal; font-family:'Times New Roman'; font-size:12pt }\n" +
  "\t\t\t.Quote { margin:12pt 43.2pt; text-align:center; line-height:105%; font-size:10.5pt; font-style:italic }\n" +
  "\t\t\t.Revision { margin-bottom:0pt; line-height:normal; font-size:10.5pt }\n" +
  "\t\t\t.Subtitle { margin-bottom:12pt; text-align:right; line-height:normal; font-family:'Calibri Light'; font-size:15pt; color:#404040 }\n" +
  "\t\t\t.TOCHeading { margin-top:20pt; margin-bottom:2pt; page-break-inside:avoid; page-break-after:avoid; line-height:normal; border-bottom:0.75pt solid #4472c4; padding-bottom:1pt; font-family:'Calibri Light'; font-size:18pt; color:#2f5496 }\n" +
  "\t\t\t.Terms1 { margin-left:18pt; margin-bottom:6pt; text-indent:-18pt; line-height:110%; font-family:'Calibri Light'; font-size:11pt }\n" +
  "\t\t\t.Terms2 { margin-left:39.6pt; margin-bottom:6pt; text-indent:-21.6pt; line-height:110%; font-family:'Calibri Light'; font-size:11pt }\n" +
  "\t\t\t.Title { margin-bottom:0pt; text-align:right; line-height:normal; font-family:'Calibri Light'; font-size:40pt; letter-spacing:-0.35pt; color:#2f5496 }\n" +
  "\t\t\tspan.BookTitle { font-weight:bold; font-variant:small-caps }\n" +
  "\t\t\tspan.CommentReference { font-size:8pt }\n" +
  "\t\t\tspan.Emphasis { font-style:italic }\n" +
  "\t\t\tspan.Hyperlink { text-decoration:underline; color:#0000ff }\n" +
  "\t\t\tspan.IntenseEmphasis { font-weight:bold; font-style:italic }\n" +
  "\t\t\tspan.IntenseReference { font-weight:bold; font-variant:small-caps; text-decoration:underline }\n" +
  "\t\t\tspan.Strong { font-weight:bold }\n" +
  "\t\t\tspan.SubtleEmphasis { font-style:italic; color:#595959 }\n" +
  "\t\t\tspan.SubtleReference { font-variant:small-caps; color:#404040 }\n" +
  "\t\t\tspan.Terms1Char { font-family:'Calibri Light'; font-size:11pt }\n" +
  "\t\t\tspan.Terms2Char { font-family:'Calibri Light'; font-size:11pt }\n" +
  "\t\t\tspan.UnresolvedMention { color:#605e5c; background-color:#e1dfdd }\n" +
  "\t\t\tspan.a6 { font-family:'Segoe UI'; font-size:9pt }\n" +
  "\t\t\tspan.a4 { font-size:10pt }\n" +
  "\t\t\tspan.\\31 { font-family:'Calibri Light'; font-size:18pt; color:#2f5496 }\n" +
  "\t\t\tspan.\\32 { font-family:'Calibri Light'; font-size:14pt; color:#2f5496 }\n" +
  "\t\t\tspan.\\33 { font-family:'Calibri Light'; font-size:13pt; color:#404040 }\n" +
  "\t\t\tspan.\\34 { font-family:'Calibri Light'; font-size:12pt }\n" +
  "\t\t\tspan.\\35 { font-family:'Calibri Light'; font-size:11pt; font-style:italic }\n" +
  "\t\t\tspan.\\36 { font-family:'Calibri Light'; color:#595959 }\n" +
  "\t\t\tspan.\\37 { font-family:'Calibri Light'; font-style:italic; color:#595959 }\n" +
  "\t\t\tspan.\\38 { font-family:'Calibri Light'; font-variant:small-caps; color:#595959 }\n" +
  "\t\t\tspan.\\39 { font-family:'Calibri Light'; font-style:italic; font-variant:small-caps; color:#595959 }\n" +
  "\t\t\tspan.a { font-family:'Calibri Light'; font-size:40pt; letter-spacing:-0.35pt; color:#2f5496 }\n" +
  "\t\t\tspan.a0 { font-family:'Calibri Light'; font-size:15pt; color:#404040 }\n" +
  "\t\t\tspan.a5 { font-size:10pt; font-weight:bold }\n" +
  "\t\t\tspan.a2 { font-family:'Calibri Light'; font-size:14pt; color:#4472c4 }\n" +
  "\t\t\tspan.a1 { font-style:italic }\n" +
  "\t\t\t.awlist1 { list-style:none; counter-reset:awlistcounter14_0 }\n" +
  "\t\t\t.awlist1 > li:before { content:counter(awlistcounter14_0) '.'; counter-increment:awlistcounter14_0 }\n" +
  "\t\t\t.awlist2 { list-style:none; counter-reset:awlistcounter14_1 }\n" +
  "\t\t\t.awlist2 > li:before { content:counter(awlistcounter14_0) '.' counter(awlistcounter14_1) '.'; counter-increment:awlistcounter14_1 }\n" +
  "\t\t\t.awlist3 { list-style:none; counter-reset:awlistcounter15_2 }\n" +
  "\t\t\t.awlist3 > li:before { content:counter(awlistcounter15_2, lower-latin) ')'; counter-increment:awlistcounter15_2 }\n" +
  "\t\t\t.awlist4 { list-style:none; counter-reset:awlistcounter14_1 }\n" +
  "\t\t\t.awlist4 > li:before { content:'2.' counter(awlistcounter14_1) '.'; counter-increment:awlistcounter14_1 }\n" +
  "\t\t\t.awlist5 { list-style:none; counter-reset:awlistcounter14_0 2 }\n" +
  "\t\t\t.awlist5 > li:before { content:counter(awlistcounter14_0) '.'; counter-increment:awlistcounter14_0 }\n" +
  "\t\t\t.awlist6 { list-style:none; counter-reset:awlistcounter14_2 }\n" +
  "\t\t\t.awlist6 > li:before { content:counter(awlistcounter14_2, lower-latin) ')'; counter-increment:awlistcounter14_2 }\n" +
  "\t\t\t.awlist7 { list-style:none; counter-reset:awlistcounter14_0 4 }\n" +
  "\t\t\t.awlist7 > li:before { content:counter(awlistcounter14_0) '.'; counter-increment:awlistcounter14_0 }\n" +
  "\t\t\t.awlist8 { list-style:none; counter-reset:awlistcounter14_0 5 }\n" +
  "\t\t\t.awlist8 > li:before { content:counter(awlistcounter14_0) '.'; counter-increment:awlistcounter14_0 }\n" +
  "\t\t\t.awlist9 { list-style:none; counter-reset:awlistcounter14_0 6 }\n" +
  "\t\t\t.awlist9 > li:before { content:counter(awlistcounter14_0) '.'; counter-increment:awlistcounter14_0 }\n" +
  "\t\t\t.awlist10 { list-style:none; counter-reset:awlistcounter14_0 7 }\n" +
  "\t\t\t.awlist10 > li:before { content:counter(awlistcounter14_0) '.'; counter-increment:awlistcounter14_0 }\n" +
  "\t\t\t.awlist11 { list-style:none; counter-reset:awlistcounter14_0 8 }\n" +
  "\t\t\t.awlist11 > li:before { content:counter(awlistcounter14_0) '.'; counter-increment:awlistcounter14_0 }\n" +
  "\t\t\t.awlist12 { list-style:none; counter-reset:awlistcounter14_3 }\n" +
  "\t\t\t.awlist12 > li:before { content:counter(awlistcounter14_3, upper-roman) '.'; counter-increment:awlistcounter14_3 }\n" +
  "\t\t\t.awlist13 { list-style:none; counter-reset:awlistcounter14_0 9 }\n" +
  "\t\t\t.awlist13 > li:before { content:counter(awlistcounter14_0) '.'; counter-increment:awlistcounter14_0 }\n" +
  "\t\t\t.awlist14 { list-style:none; counter-reset:awlistcounter14_0 10 }\n" +
  "\t\t\t.awlist14 > li:before { content:counter(awlistcounter14_0) '.'; counter-increment:awlistcounter14_0 }\n" +
  "\t\t\t.awlist15 { list-style:none; counter-reset:awlistcounter27_0 }\n" +
  "\t\t\t.awlist15 > li:before { content:counter(awlistcounter27_0, lower-latin) ')'; counter-increment:awlistcounter27_0 }\n" +
  "@media (max-width: 900px) { \n" +
  "img { \n" +
  "   max-width: 100%;\n" +
  "   height: auto;\n" +
  "}\n" +
  "\n" +
  ".table-container {\n" +
  "    overflow-x: auto;\n" +
  "    -webkit-overflow-scrolling: touch;\n" +
  "}\n" +
  "\n" +
  "table {\n" +
  "    width: 100%;\n" +
  "    border-collapse: collapse;\n" +
  "}\n" +
  "\n" +
  "td, th {\n" +
  "    padding: 8px;\n" +
  "    text-align: left;\n" +
  "    border: 1px solid #ddd;\n" +
  "}\n" +
  "}\t\n" +
  "\n" +
  "\n" +
  "\t\t</style>\n" +
  "\t</head>\n" +
  "\t<body>\n" +
  "\t\t<div dir=\"rtl\">\n" +
  "\t\t\t<p dir=\"ltr\" style=\"margin-bottom:14pt; text-align:left; line-height:normal; font-size:24pt; background-color:#ffffff\">\n" +
  "\t\t\t\t<span style=\"font-family:Verdana; font-weight:bold; color:#212529\" dir=\"ltr\">GeoX Web Demo Terms of Use</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">Welcome to GeoX Web Demo! It provides you with easy, online access to our Data.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">These GeoX Web Demo Terms of Use (<strong>GeoX Web </strong>Demo<strong> Terms</strong>) set out the terms and conditions on which you can use GeoX Web Demo and our GeoX Web Demo website located at&#xa0;</span><a href=\"https://geoxanalytics.com/us-db\" target=\"_blank\" style=\"text-decoration:none\"><span class=\"Hyperlink\" dir=\"ltr\">https://geoxanalytics.com/us-db</span></a><span dir=\"ltr\">&#xa0;<strong>.</strong></span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">These GeoX Web Demo Terms legally bind you where you:</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">You agree that where you access the GeoX Web Demo Website, you do so on behalf of your organization, unless you are&#xa0; accessing GeoX Web Demo Web site as an individual.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">If you are using GeoX Web Demo as an employee, officer, agent, contractor or other representative of an organization, you warrant to us that you have full legal authority to bind your organization to these GeoX Web Demo Terms.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol class=\"awlist1\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9.73pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">Evaluating our Trial Data</span>\n" +
  "\t\t\t\t\t<ol class=\"awlist2\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">From time to time we may make Trial Data available through GeoX Web Demo.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-21.6pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\"><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\">We grant you a limited, worldwide, non-exclusive, non-sublicensable, revocable, non-transferrable license to use Trial Data strictly for the purpose of internally evaluating the suitability of GeoX Web </span>Demo <span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\">and the Trial Data for your business needs (the</span><span class=\"IntenseEmphasis\" style=\"font-style:normal\">&#xa0;</span><span class=\"IntenseEmphasis\" style=\"font-style:normal\">Permitted Purpose</span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\">) in accordance with these GeoX Web </span>Demo <span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\">Terms.</span></span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-21.6pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">You must not commercially exploit Trial Data in any way, including by using it to develop capability (including machine learning capability), products, services, outputs or other material (including machine learning algorithms) for commercialization or potential commercialization.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-21.6pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">You may download Trial Data that we explicitly make available for download. However, you must not otherwise download, extract, scrape or otherwise harvest data from GeoX Web Platform.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-21.6pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">Your license to use Trial Data may be:</span><br /><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t\t\t\t<ol class=\"awlist3\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.74pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">limited to the trial period nominated by us, which we may change by notice to you; and</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.2pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">subject to other restrictions or limitations notified to you by us from time to time.</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t</ol>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t</ol>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-bottom:0pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9.73pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">GeoX web Demo Terms</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol class=\"awlist4\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:39.6pt; margin-bottom:0pt; text-indent:-21.6pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">We make no representations or warranties regarding the accuracy or correctness of the Trial Data, or that the Trial Data is suitable for your particular business needs.</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:39.6pt; text-indent:-21.6pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span class=\"IntenseEmphasis\" style=\"font-weight:normal; font-style:normal\" dir=\"ltr\">We warrant that we are entitled to grant you the license to use the Trial Data pursuant to clause 1.2 and that the grant of that license does not infringe the Intellectual Property of any third party.</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-bottom:0pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">We may vary the features, functions and other benefits available to you and your Authorized&#xa0; Users as part of&#xa0; GeoX Web Demo Website from time to time, without any requirement to provide prior notice of the variation to you.</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-bottom:0pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">Nothing in these GeoX Web Demo Terms imposes any obligation on us to develop, release or install for you any updates, upgrades, patches, bug fixes, new releases or new versions in respect of GeoX Web Demo (<strong>Updates</strong>). However, if we do develop or release any Updates, we may require that all such Updates be used by you and any Authorized&#xa0; User.</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-bottom:0pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">We make the GeoX Web Demo Website and the information and material on the GeoX Web Demo Website available for general informational purposes only and we do not make any representations, warranties or guarantees of any kind, express or implied, as to the operation, suitability, accuracy or currency of the GeoX Web Demo Website or the information or material available on the GeoX Web Demo Website.</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\"><span style=\"background-color:#ffff00\">You agree that we may vary these GeoX Web Demo Terms from time to time.</span> </span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<ol start=\"3\" class=\"awlist5\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9.73pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">Third Party Applications</span>\n" +
  "\t\t\t\t\t<ol class=\"awlist2\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">GeoX Web Demo Website may interact with Third Party Applications, or require Third Party Applications or Third Party Providers be used to provide particular features or functionality.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">You acknowledge that these Third Party Applications are subject to Third Party Provider Terms (set out in clause 4.4), and you must ensure that your use (including use by any Authorized&#xa0; User) of these Third Party Applications complies with the relevant Third Party Provider Terms.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">We do not make any representations or warranties regarding any Third Party Applications.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">GeoX Web Demo uses Third Party Applications which are subject to the following terms (<strong>Third Party Provider Terms</strong>):</span>\n" +
  "\t\t\t\t\t\t\t<ol class=\"awlist6\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt; line-height:normal; color:#212529; background-color:#ffffff\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.74pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">Amazon Web Services:&#xa0;</span><a href=\"https://aws.amazon.com/legal/\" style=\"text-decoration:none\"><u><span style=\"color:#007bff\" dir=\"ltr\">https://aws.amazon.com/legal</span></u></a><span dir=\"ltr\">;</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.2pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\"><span style=\"color:#212529\">Google: </span>Your responsibilities and obligations</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t</ol>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t</ol>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9.73pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">Your responsibilities and obligations</span>\n" +
  "\t\t\t\t\t<ol class=\"awlist2\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">You are responsible for ensuring that your use, and use by your Authorized&#xa0; Users, of GeoX Web Demo complies with all applicable laws, regulations or codes of conduct and does not infringe the Intellectual Property or other rights of any person.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">You must:</span><br /><span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t\t\t\t<ol class=\"awlist6\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.74pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">not modify, adapt, translate, reverse engineer, de-compile, disassemble or copy all or any part of GeoX Web Demo Website;</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.2pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">immediately notify us you become aware of any breach of the provisions of these GeoX Web Demo Terms by you or an Authorized&#xa0; User;</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:17.23pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">comply with any Documentation made available by us to you from time to time;</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.2pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">not attempt to circumvent or break any encryption, decryption or other security device or technological protection measure contained in GeoX Web Demo ;</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.48pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">not distribute any part of GeoX Web Demo for commercial purposes or otherwise sub-license or resell access to GeoX Web Demo ;</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:18.63pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">not permit any person other than your Authorized&#xa0; Users to use GeoX Web Demo ; and</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.76pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">not, and must ensure that your Authorized&#xa0; Users do not, upload any Unacceptable Content to GeoX Web Demo .</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t</ol>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">If we reasonably believe that any Unacceptable Content has been uploaded to GeoX Web Demo, we may remove this Unacceptable Content.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t</ol>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" style=\"text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol start=\"5\" class=\"awlist7\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-bottom:0pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9.73pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">Privacy</span><br /><span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">We handle any Personal Information collected in the course of providing you and your Authorized&#xa0; Users with GeoX Web Demo in accordance with our&#xa0;</span><a href=\"https://geoxproperty.com/privacy-policy/\" style=\"text-decoration:none\"><u><span style=\"color:#007bff\" dir=\"ltr\">Privacy Policy.</span></u></a>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" style=\"text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol start=\"6\" class=\"awlist8\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-bottom:0pt; text-align:left; font-family:'Times New Roman'\">\n" +
  "\t\t\t\t\t<span style=\"width:9.75pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style=\"font-family:'Calibri Light'; background-color:#ffffff\" dir=\"ltr\">Availability</span><br /><span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">We strive to makeGeoX Web Demo Website available 24/7, but from time to time GeoX Web Demo Website, or some of their features or functions, may be unavailable (for example, due to scheduled or unscheduled maintenance of the GeoX Web Demo Website or GeoX Infrastructure). We will give you notice of any scheduled unavailability of GeoX Web Demo Website, where we can reasonably do so.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol start=\"7\" class=\"awlist9\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9.73pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">Confidentiality</span>\n" +
  "\t\t\t\t\t<ol class=\"awlist2\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">Both parties:</span><br /><span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t\t\t\t<ol class=\"awlist6\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.74pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">may use Confidential Information solely for the purposes reasonably contemplated by these GeoX Web Demo Terms;</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.2pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">subject to 10.1(c), must keep confidential all Confidential Information; and</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:17.23pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">may disclose Confidential Information only to (i) their employees, contractors and professional advisors who (A) are aware and agree that the Confidential Information must be kept confidential and (B) have a need to know the Confidential Information (and only to the extent that each has a need to know); (ii) as required by law or securities exchange regulation; and (iii) with the prior written consent of the other party.</span>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t</ol>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">Each party must notify the other immediately if they become aware of any breach of confidentiality in respect of the Confidential Information and must take all reasonable steps necessary to prevent further Unauthorized&#xa0; use or disclosure of the Confidential Information.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t</ol>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" style=\"text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol start=\"8\" class=\"awlist10\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-bottom:0pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9.73pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">Intellectual Property</span><br /><span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">GeoX owns all worldwide right, title and interest in and to the GeoX Web Demo Website, the Data, the Documentation, and&#xa0; all of GeoX’ proprietary database, tools, models, methods, software, procedures, algorithms, documentation, concepts, know-how, processes, user interfaces, trade secrets, inventions and works of authorship, including all worldwide Intellectual Property therein. Nothing in these GeoX Web Demo Terms grants you any ownership of or rights in respect of the Intellectual Property in GeoX Web Demo Website, our Data, or any Documentation.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" style=\"text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol start=\"9\" class=\"awlist11\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9.73pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">Liability and warranties</span>\n" +
  "\t\t\t\t\t<ol class=\"awlist2\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">Notwithstanding any other provision of these GeoX Web Demo Terms:</span><br /><span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t\t\t\t<ol class=\"awlist6\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.74pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">we will not be liable for any Loss suffered by you which arises out of or in connection with:</span><br /><span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t\t\t\t\t\t<ol class=\"awlist12\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:25.2pt; margin-bottom:0pt; text-indent:-37.78pt\">\n" +
  "\t\t\t\t\t\t\t\t\t\t\t<span style=\"width:32.4pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">your use or inability to use the GeoX Web Demo Website or our Data.</span>\n" +
  "\t\t\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:25.2pt; margin-bottom:0pt; text-indent:-40.46pt\">\n" +
  "\t\t\t\t\t\t\t\t\t\t\t<span style=\"width:32.4pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">any computer viruses or malicious code being transferred by or obtained as a result of the use of the GeoX Web Demo Website; or</span>\n" +
  "\t\t\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:25.2pt; margin-bottom:0pt; text-indent:-43.15pt\">\n" +
  "\t\t\t\t\t\t\t\t\t\t\t<span style=\"width:32.4pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">any hacking into, data security issues or other similar attacks on GeoX Web Demo or the GeoX Infrastructure;</span>\n" +
  "\t\t\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t\t</ol>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; text-indent:-25.2pt\">\n" +
  "\t\t\t\t\t\t\t\t\t<span style=\"width:16.2pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">we do not warrant or guarantee that:</span><br /><span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t\t\t\t\t\t<ol class=\"awlist12\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:25.2pt; margin-bottom:0pt; text-indent:-37.78pt\">\n" +
  "\t\t\t\t\t\t\t\t\t\t\t<span style=\"width:32.4pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">your access to GeoX Web Demo Website will be uninterrupted, error free or free from corruption, viruses, interference, hacking or other security intrusion; or</span>\n" +
  "\t\t\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:25.2pt; margin-bottom:0pt; text-indent:-40.46pt\">\n" +
  "\t\t\t\t\t\t\t\t\t\t\t<span style=\"width:32.4pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">GeoX Web Demo Website will be accurate, reliable or fit for any particular purpose.</span>\n" +
  "\t\t\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t\t\t</ol>\n" +
  "\t\t\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t\t</ol>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">GEOX EXPRESSLY DISCLAIMS ANY AND ALL OTHER WARRANTIES OF ANY KIND OR NATURE, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. GEOX DOES NOT WARRANT THAT THE DATA WILL BE ERROR-FREE OR WILL MEET YOUR REQUIREMENTS.&#xa0; </span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">Neither party will be liable for any Consequential Loss (however caused) suffered or incurred by the other party in connection with these GeoX Web Demo terms.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:5.07pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">GEOX’ AGGREGATE LIABILITY UNDER THESE GEOX WEB DEMO TERMS SHALL NOT EXCEED THE FEES PAID TO GEOX HEREUNDER DURING THE THREE-MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY. GEOX' SOLE LIABILITY AND YOUR SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY CLAIMS OR DEMANDS YOU MAY HAVE IN CONNECTION WITH THE GEOX WEB DEMO SHALL BE THE CORRECTION OF ANY ERROR BY GEOX AT NO COST TO YOU.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t</ol>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms2\" style=\"margin-left:18pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol start=\"10\" class=\"awlist13\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:4.16pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span dir=\"ltr\">General provisions</span>\n" +
  "\t\t\t\t\t<ol class=\"awlist2\" style=\"margin-right:0pt; margin-left:0pt; padding-left:0pt\">\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; list-style-position:inside\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:31.89pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">These GeoX Web Demo Terms are governed by the laws in force in Israel, and the parties submit to the non-exclusive jurisdiction of the courts of Tel Aviv Jaffa.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; list-style-position:inside\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:31.89pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">A party will not be liable for any failure to perform or delay in performing its obligations under these GeoX Web Demo terms if that failure or delay is due to a Force Majeure Event and it has provided notice to the other party of the Force Majeure Event.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t\t<li dir=\"ltr\" class=\"Terms2\" style=\"margin-left:21.6pt; margin-bottom:0pt; list-style-position:inside\">\n" +
  "\t\t\t\t\t\t\t<span style=\"width:31.89pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">If any provision of these GeoX Web Demo terms is void, unenforceable or illegal and would not be so if a word or words were omitted, then that word or those words are to be severed and if this cannot be done, the entire provision is to be severed from these GeoX Web Demo terms without affecting the validity or enforceability of the remaining provisions of these GeoX Web Demo terms.</span>\n" +
  "\t\t\t\t\t\t</li>\n" +
  "\t\t\t\t\t</ol>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms2\" style=\"margin-left:18pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms2\" style=\"text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol start=\"11\" class=\"awlist14\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-bottom:0pt; text-align:left; font-family:'Times New Roman'\">\n" +
  "\t\t\t\t\t<span style=\"width:4.25pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0; </span><span style=\"font-family:'Calibri Light'; background-color:#ffffff\" dir=\"ltr\">Definitions</span><br /><span dir=\"ltr\">&#xa0;</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">In these GeoX Web Demo terms:</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Authorized</strong><strong>&#xa0; </strong><strong>Users</strong>&#xa0;has the meaning given in clause 2.1.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Confidential Information</strong>&#xa0;means any information provided by a party to the other party in connection with the GeoX Web Demo or these GeoX Web Demo Terms but does not include information which is in or becomes part of the public domain, other than through a breach of these GeoX Web Demo Terms or of an obligation of confidence, information received by a party from a third party not in violation of any obligation of confidentiality or information which a party proves was independently acquired or developed without breaching any of the obligations set out in these GeoX Web Demo Terms.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Consequential Loss</strong>&#xa0;means:</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<ol class=\"awlist15\" style=\"margin:0pt\">\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:36pt; margin-bottom:0pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9.54pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">any form of indirect, special or consequential loss, including loss of reputation, loss of profits, loss of data, loss of actual or anticipated savings, loss of bargain and loss of opportunity; and</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t\t<li dir=\"ltr\" class=\"Terms1\" style=\"margin-left:36pt; margin-bottom:0pt; text-align:left\">\n" +
  "\t\t\t\t\t<span style=\"width:9pt; font:7pt 'Times New Roman'; display:inline-block\">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span dir=\"ltr\">any loss beyond the normal measure of damages.</span>\n" +
  "\t\t\t\t</li>\n" +
  "\t\t\t</ol>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Data</strong>&#xa0;means the information and data made available to you and Authorized&#xa0; Users through GeoX Web Platform, including any data attributes of buildings.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Documentation</strong>&#xa0;means any manuals, guides, reference materials or other similar documents in any form made available by us to you in connection with GeoX Web Demo .</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Force Majeure Event</strong>&#xa0;means any event arising from, or attributable to, acts, events, omissions or accidents which are beyond the reasonable control of a party.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>GeoX Web </strong>Demo <strong>Account</strong>&#xa0;means the account that you register for in order to be able to access and use GeoX Demo Platform.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>GeoX Web Demo Portal</strong>&#xa0;means the data delivery platform which is made available by us at&#xa0;</span><a href=\"https://geoxanalytics.com/us-db\" target=\"_blank\" style=\"text-decoration:none\"><span class=\"Hyperlink\" dir=\"ltr\">https://geoxanalytics.com/us-db</span></a><span dir=\"ltr\">&#xa0;&#xa0;for the use by you or your Authorized&#xa0; Users from time to time including any Update to that platform implemented by us and any improvements to that platform.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Intellectual Property</strong>&#xa0;includes all patents, designs, copyright, trade marks, service marks, trade secrets, know how, moral rights and any right to apply for the registration or grant of any of the above.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Loss</strong>&#xa0;means any loss, liability, cost, claim, expense, damage, charge, penalty, outgoing or payment however arising, whether present, unascertained, immediate, future or contingent, and includes direct loss and Consequential Loss.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Personal Information</strong>&#xa0;has the meaning given to it under applicable privacy laws.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>GeoX Infrastructure</strong>&#xa0;means the cloud services, web infrastructure, computer servers or other hardware used by us in connection with our provision of GeoX Web Platform.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>GeoX Policies</strong>&#xa0;means our then current policies regarding the use of GeoX Web Demo , as we may publish in the GeoX Web Demo or notify you of from time to time.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Third Party Application</strong>&#xa0;means any product, service, system, application or internet site integrated or interfaced with GeoX Web Demo that is owned or operated by a Third Party Provider.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Third Party Provider</strong>&#xa0;means any third party that provides products or services that are used by you or an Authorized&#xa0; User in connection with GeoX Web Demo .</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Third Party Provider Terms</strong>&#xa0;has the meaning given to in pursuant to clause 4.4.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Trial</strong>&#xa0;means allowing you access to our Trial Data on a short term trial basis.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Trial Data</strong>&#xa0;means any Data that we allow you to access through GeoX Web Demo without signing up to a GeoX Web Platform subscription.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>Unacceptable Content</strong>&#xa0;means:</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">content which, in our reasonably held opinion, is obscene, offensive, upsetting, defamatory, illegal or inappropriate, infringes or appears to infringe the Intellectual Property rights of any person or contravenes or appears to contravene any applicable laws, regulations or codes of conduct;</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">content which is incorrect, false or misleading; or</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\">files with any viruses, malicious code or other conditions which could damage or interfere with data, hardware or software.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>We or us</strong>&#xa0;means GeoX GIS Innovations Ltd , registered co: 515832186.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t\t<p dir=\"ltr\" class=\"Terms1\" style=\"margin-left:0pt; text-indent:0pt; text-align:left\">\n" +
  "\t\t\t\t<span dir=\"ltr\"><strong>You or your</strong>&#xa0;means the person (where an individual has agreed to these terms on their own behalf) or entity (where an individual has agreed to these terms on behalf of an entity) who registers with us for a GeoX Web Platform Account.</span>\n" +
  "\t\t\t</p>\n" +
  "\t\t</div>\n" +
  "</body>\n" +
  "</html>";