import styled from "styled-components/macro";
import * as AlertDialogRadix from "@radix-ui/react-alert-dialog";
import { keyframes } from "styled-components";

import { BORDER_RADIUS, COLORS, FONTS, ModalButton, QUERIES, WEIGHT } from "configs/styles/common";

import { text, textSM, title, titleLG } from "../Typography";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const scaleInTop = keyframes`
  from {
    transform: scale(0);
    transform-origin: 50% 0%;
    opacity: 1;
  }
  
  to {
    transform: scale(1);
    transform-origin: 50% 0%;
    opacity: 1;
  }
`;

type OverlayProps = {
  $overlayColor?: string;
};

export const Overlay = styled(AlertDialogRadix.Overlay)<OverlayProps>`
  position: fixed;
  inset: 0;
  z-index: 50;
  backdrop-filter: blur(4px);
  animation: ${fadeIn} 400ms cubic-bezier(0.14, 0.78, 0.36, 1);
  background-color: ${({ $overlayColor }) => ($overlayColor ? $overlayColor : "rgb(255 255 255 / 80%)")};
  overflow: auto;
  padding: 20px 15px;
  display: flex;
`;

export const Content = styled(AlertDialogRadix.Content)`
  max-width: var(--max-width);
  margin: auto;
  position: relative;
  padding: 24px 36px;
  display: grid;
  place-items: center;
  box-shadow: rgb(0 0 0 / 0%) 0 0 0 0, rgb(0 0 0 / 0%) 0 0 0 0, rgb(0 0 0 / 10%) 0 10px 15px -3px,
    rgb(0 0 0 / 10%) 0 4px 6px -4px;
  border-radius: ${BORDER_RADIUS.MODAL};
  background-color: ${COLORS.LIGHT.White};
  border: 1px solid rgb(228 228 231);
  width: 90vw;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${scaleInTop} 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
`;

export const Header = styled.div`
  margin-bottom: 0.5rem;
  text-align: center;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const Title = styled(AlertDialogRadix.Title)`
  ${titleLG};
  font-weight: ${WEIGHT.semiBold};
  color: ${COLORS.DARK.Main};
  font-family: ${FONTS.Title};
  text-align: center;
  margin-bottom: 0.5rem;

  @media ${QUERIES.upToMobile} {
    ${title};
  }
`;

export const Description = styled(AlertDialogRadix.Description)`
  ${text};
  text-align: center;
  color: ${COLORS.DARK.Secondary};
  margin-bottom: 1rem;
`;

export const ActionButton = styled(ModalButton)`
  ${textSM};
  width: 95px;
  height: 40px;
`;

export const CancelButton = styled(ActionButton)`
  background: ${COLORS.LIGHT.White};
  color: ${COLORS.DARK.Main};
`;
