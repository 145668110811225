import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ColumnsBuilder } from "components/presenters/special/Tables/tableColumns/helpers/columnsBuilder";
import { checkIsObjectEmpty } from "services/utils/validation/checkIsObjectEmpty";

import { DateCell, NumberCell, ImageCell, FlagsCell } from "../components/ParcelsTable/TableCells";
import { ATTRIBUTES } from "../utils/consts";
import { SortingCell } from "../components/SortingCell";
import * as Styled from "../utils/style";

export function useTableColumns({ handleExpand, setParams, params }) {
  const { t } = useTranslation("parcels-table");

  const handleSortChange = useCallback(
    (field, order) => {
      setParams((prevParams) => {
        const newOrder = order ? { ...prevParams.orderBy, [field]: order } : { ...prevParams.orderBy };

        if (!order) {
          delete newOrder[field];
        }

        return checkIsObjectEmpty(newOrder)
          ? { ...prevParams, orderBy: undefined }
          : { ...prevParams, orderBy: newOrder };
      });
    },
    [setParams]
  );

  const columns = useMemo(() => {
    const builder = new ColumnsBuilder()
      .addColumn({
        accessorKey: ATTRIBUTES.number,
        header: t(ATTRIBUTES.number),
        cell: ({ row }) => <NumberCell handleExpand={handleExpand} row={row} />,
        align: "flex-start",
        size: 80,
      })
      .addColumn({
        accessorKey: ATTRIBUTES.address,
        header: t(ATTRIBUTES.address),
        cell: ({ row }) => <span>{row.original.address}</span>,
        size: 350,
        align: "flex-start",
      })
      .addColumn({
        accessorKey: "id",
        header: t(ATTRIBUTES.externalId),
        cell: ({ row }) => <span>{row.original.originalParcelId}</span>,
        align: "flex-start",
      })
      .addColumn({
        accessorKey: ATTRIBUTES.image,
        header: t(ATTRIBUTES.image),
        cell: ({ row }) => <ImageCell row={row} />,
        size: 100,
      })
      .addColumn({
        accessorKey: ATTRIBUTES.totalFootprint,
        header: t(ATTRIBUTES.totalFootprint),
        cell: ({ row }) => <span>{row.original.footprintsCount}</span>,
        size: 120,
      })
      .addColumn({
        accessorKey: ATTRIBUTES.flags,
        header: t(ATTRIBUTES.flags),
        cell: ({ row }) => <FlagsCell flags={row.original.flags} />,
      })
      .addColumn({
        accessorKey: ATTRIBUTES.date,
        header: (
          <SortingCell
            columnId={ATTRIBUTES.date}
            cellName={t(ATTRIBUTES.date)}
            onSortChange={handleSortChange}
            currentOrder={params.orderBy?.[ATTRIBUTES.date]}
          />
        ),
        cell: ({ row }) => <DateCell date={row.original.createdAt} />,
      })
      .addColumn({
        accessorKey: ATTRIBUTES.risk,
        header: (
          <SortingCell
            columnId={ATTRIBUTES.risk}
            cellName={t(ATTRIBUTES.risk)}
            onSortChange={handleSortChange}
            currentOrder={params.orderBy?.[ATTRIBUTES.risk]}
          />
        ),
        cell: ({ row }) => <Styled.RiskCell $value={row.original.risk}>{row.original.risk}</Styled.RiskCell>,
      });

    return builder.build();
  }, [t, handleExpand, handleSortChange, params?.orderBy]);

  return columns;
}
