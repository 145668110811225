import React from "react";

import { SEO } from "components/seo";

import { TOS } from "./assets/TermsOfService";
import { TosWrapper } from "./style";

function NhDemoTosPage() {
  return (
    <>
      <SEO title="TOS" />
      <TosWrapper dangerouslySetInnerHTML={{ __html: TOS }} />
    </>
  );
}

export default NhDemoTosPage;
