import React from "react";
import styled, { css } from "styled-components";

import { dots } from "assets/_vectors/cornerDots";

import { text, titleLG } from "../../components/presenters/common/Typography";

export const containerWidth = () => {
  switch (true) {
    case window.innerWidth < 940:
      return "calc(100vw - 40px * 2)";
    case window.innerWidth <= 1100:
      return "calc(100vw - 80px * 2)";
    case window.innerWidth <= 1500:
      return "calc(100vw - 100px * 2)";
    case window.innerWidth <= 1920:
      return "calc(100vw - 160px * 2)";
    default:
      return "calc(100vw - 250px * 2)";
  }
};

export const BREAKPOINTS = {
  mobileMax: 550,
  tabletMax: 1100,
  laptopMax: 1500,
};

export const QUERIES = {
  upToMobile: `(max-width: ${BREAKPOINTS.mobileMax / 16}rem)`,
  upToTablet: `(max-width: ${BREAKPOINTS.tabletMax / 16}rem)`,
  upToLaptop: `(max-width: ${BREAKPOINTS.laptopMax / 16}rem)`,
  onlyTablet: `(min-width: ${BREAKPOINTS.mobileMax / 16}rem) and (max-width: ${BREAKPOINTS.tabletMax / 16}rem)`,
};

export const BORDER_RADIUS = {
  XS: "6px",
  S: "10px",
  M: "16px",
  MODAL: "8px",
  ICON: "4px",
};

export const mobileContainerStyle = css`
  max-width: 530px;
  padding: 0 20px;
`;

export const Container = styled.div`
  max-width: calc(100vw - 250px * 2);
  margin: 0 auto;
  width: 100%;

  @media (max-width: 1920px) {
    max-width: calc(100vw - 160px * 2);
  }

  @media ${QUERIES.upToLaptop} {
    max-width: calc(100vw - 100px * 2);
  }

  @media ${QUERIES.upToTablet} {
    max-width: calc(100vw - 80px * 2);
  }

  @media (max-width: 940px) {
    max-width: calc(100vw - 40px * 2);
  }

  @media ${QUERIES.upToMobile} {
    max-width: calc(100vw - 20px * 2);
  }
`;

export const gridCenter = { display: "grid", placeItems: "center" };

export const singleSearchMobileQuery = "(max-width: 690px)";

export const BOX_SHADOW = {
  LIGHT: "2px 2px 10px 0 rgb(0 0 0 / 10%)",
  REGULAR: "0 3px 7px rgba(0, 0, 0, 0.11)",
  BOLD: "0 4px 14px rgba(0 0 0 / 13%)",
  ICON_BUTTON: "0 0 0.333rem rgb(0 0 0 / 16%)",
  SUBHEADER: "rgb(149 157 165 / 20%) 0 3px 18px",
  NEW: "0 2px 2px rgba(0 0 0 / 0.15)",
};

export const FONTS = {
  Main: "'Work Sans Variable', sans-serif",
  Title: "'Bitter Variable', serif",
};

export const WEIGHT = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const COLORS = {
  INPUT: {
    Placeholder: "#b2b1b1",
    Error: "#d12727",
    ErrorBg: "#ffe1e1",
    Success: "#22c55e",
    AccentPlaceholder: "#877a7a",
  },
  ACCENT: "#ffcd00",
  DARK: {
    Main: "#23242a",
    Secondary: "#54565a",
    Brown: "#423f3aff",
  },
  LIGHT: {
    White: "#fff",
    Accent: "#ffcd003a",
    Background: "#faf9f5",
    Slate: "#f5f5f4ff",
    SlateDarker: "#e2e8f0",
    MiniBackground: "#f8f7f6",
  },
  GREY: {
    Main: "#cbc8c8",
    Secondary: "#d9d9d9",
    Form: "#808080",
    Neutral: "#a3a8a9",
    Background: "#f6f6f6",
  },
  PRIMARY: {
    Blue: "#0b809b",
    Link: "#007AFF",
  },
};

export const MAP_COLORS = {
  ORANGE: "#ff8500",
  PURPLE: "#ae3f9e",
};

export const headerHeight = {
  laptop: 70,
  tablet: 100,
  mobile: 75,
};

export const baseButtonStyle = css`
  cursor: pointer;
  display: grid;
  place-content: center;
  border: none;
  padding: 0;
  width: 100%;
  background-color: transparent;
  color: ${COLORS.DARK.Main};
  font-weight: ${WEIGHT.medium};

  &:focus {
    outline: none;
  }
`;

export const baseTitleStyle = css`
  font-weight: ${WEIGHT.medium};
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.DARK.Main};
  white-space: nowrap;
  margin: 0;

  @media ${QUERIES.upToMobile} {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const centerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalButton = styled.button`
  ${text};
  ${baseButtonStyle};
  background-color: ${COLORS.ACCENT};
  border-radius: 5px;
  max-width: 220px;
  height: 50px;
  margin: 0 auto;
  border: 1.5px solid ${COLORS.DARK.Main};
  font-weight: ${WEIGHT.medium};
  font-size: ${({ lang }) => lang === "ja" && "var(--14px)"};
  transition: all 400ms;
  letter-spacing: ${({ lang }) => lang !== "ja" && "0.6px"};

  @media ${QUERIES.upToMobile} {
    font-size: ${({ lang }) => lang === "ja" && "var(--12px)"};
  }

  &:hover {
    background-color: transparent;
  }

  &:focus {
    outline: 0.5px solid ${COLORS.ACCENT};
  }
`;

export const iconButtonStyle = css`
  ${baseButtonStyle};
  background-color: ${COLORS.LIGHT.White};
  box-shadow: ${BOX_SHADOW.ICON_BUTTON};
  border-radius: ${BORDER_RADIUS.XS};
  width: 45px;
  height: 40px;
  transition: transform 300ms;

  &:hover {
    transform: scale(1.03);
  }

  &:focus {
    outline: 1px solid ${COLORS.DARK.Main};
  }
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 24px;
  border-radius: ${BORDER_RADIUS.MODAL};
`;

export const ModalTitle = styled.h3`
  ${titleLG};
  font-weight: ${WEIGHT.semiBold};
  color: ${COLORS.DARK.Main};
  font-family: ${FONTS.Title};
  text-align: center;
  margin-bottom: 6px;
`;

export const ModalText = styled.p`
  ${text};
  text-align: center;
  color: ${COLORS.DARK.Secondary};
`;

export const ControlPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: center;
  margin-top: auto;
`;

export const DeleteWrapper = styled.button`
  ${baseButtonStyle};
  width: 30px;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  padding-right: 24px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 35px;

  & svg {
    height: 16px;
  }
`;

const StyledDots = styled.div`
  position: absolute;
  ${(props) => props.positions[0] + ":" + props.pos};
  ${(props) => props.positions[1] + ":" + props.pos};
  ${(props) => props.transformString};

  &.visible circle {
    animation: radius-animation 0.35s ease-in forwards;
    r: 0;
  }

  @keyframes radius-animation {
    from {
      r: 0;
    }

    to {
      r: 2;
    }
  }
`;

export const CornerDots = ({ place }) => {
  const positions = place.split(" ");
  const X = place.includes("right") ? "-1" : "1";
  const Y = place.includes("bottom") ? "-1" : "1";
  const transformString = `transform: scale(${
    (X * parseFloat(getComputedStyle(document.documentElement).fontSize)) / 18
  }, ${(Y * parseFloat(getComputedStyle(document.documentElement).fontSize)) / 18})`;

  const pos = parseFloat(getComputedStyle(document.documentElement).fontSize) - 18 + "px";

  return (
    <StyledDots positions={positions} transformString={transformString} pos={pos} className="dots">
      {dots}
    </StyledDots>
  );
};
