import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import { InfiniteScrollTable } from "components/presenters/special/Tables/InfiniteScrollTable";

import { useParcels } from "../../hooks/useParcels";
import { useTableColumns } from "../../hooks/useTableColumns";
import { AttributeSelect } from "../AttributeSelect";
import { FlagSelect } from "../FlagSelect";
import { FilterButton } from "../FilterButton";
import { FilterWrapper } from "../../utils/style";
import { AddFootprintModal } from "../AddFootprintModal";
import { PAGINATION_LIMIT } from "../../utils/consts";
import { useParcelsData } from "./hooks/useParcelsData";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useSortingState } from "./hooks/useSortingState";

export function ParcelsTable() {
  const { id } = useParams();
  const [params, setParams] = useQueryParams();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading, isError } = useParcels({
    id,
    limit: PAGINATION_LIMIT,
    params,
  });

  const { parcelsData, handleExpand } = useParcelsData(data);
  const { sortingState } = useSortingState(params);

  const COLUMNS = useTableColumns({ handleExpand, setParams, params });

  const loadMoreRows = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  return (
    <InfiniteScrollTable
      columns={COLUMNS}
      isUpdatingData={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      loadMoreRows={loadMoreRows}
      data={parcelsData}
      isError={isError}
      isLoading={isLoading}
      sortingState={sortingState}
    >
      <AddFootprintModal />

      <FilterWrapper>
        <AttributeSelect />
        <FlagSelect />
        <FilterButton />
      </FilterWrapper>
    </InfiniteScrollTable>
  );
}
