import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as SortUpSVG } from "assets/icons/sort-up.svg";
import { ReactComponent as SortDownSVG } from "assets/icons/sort-down.svg";
import { VisuallyHidden } from "components/presenters/common/VisuallyHidden";

import * as Styled from "./style";
import { SORTING_ORDER } from "../../utils/consts";

export function SortingCell({ cellName, columnId, onSortChange, currentOrder }) {
  const { t } = useTranslation("tooltips");

  const handleSort = (newOrder) => {
    if (currentOrder === newOrder) {
      onSortChange(columnId, null);
    } else {
      onSortChange(columnId, newOrder);
    }
  };

  return (
    <Styled.CellWrapper>
      <span>{cellName}</span>

      <Styled.SortingButton
        $isSorted={currentOrder === SORTING_ORDER.DESC}
        onClick={() => handleSort(SORTING_ORDER.DESC)}
      >
        <SortDownSVG />
        <VisuallyHidden>{t("Sort Descending")}</VisuallyHidden>
      </Styled.SortingButton>

      <Styled.SortingButton
        $isSorted={currentOrder === SORTING_ORDER.ASC}
        onClick={() => handleSort(SORTING_ORDER.ASC)}
      >
        <SortUpSVG />
        <VisuallyHidden>{t("Sort Ascending")}</VisuallyHidden>
      </Styled.SortingButton>
    </Styled.CellWrapper>
  );
}
