import { useMemo } from "react";

export function useSortingState(params) {
  const sortingState = useMemo(
    () =>
      params?.orderBy
        ? Object.entries(params.orderBy).map(([field, order]) => ({
            id: field,
            desc: order === "desc",
          }))
        : {},
    [params?.orderBy]
  );

  return { sortingState };
}
