import React from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import * as Styled from "../../../utils/style";

export function NumberCell({ row, handleExpand }) {
  return (
    <Styled.NumberCell
      style={{
        "--padding-left": `${row.depth * 30}px`,
      }}
    >
      {row.depth === 0 ? (
        <Styled.ExpandButton
          $isExpanded={row.getIsExpanded()}
          {...{
            onClick: () => handleExpand(row),
          }}
        >
          <ChevronDownIcon />
        </Styled.ExpandButton>
      ) : null}
      <span>{row.index + 1}</span>
    </Styled.NumberCell>
  );
}
