import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { formatOrderBy, parseOrderBy } from "../utils/formatOrderBy";

export function useQueryParams(defaultParams = {}) {
  const history = useHistory();
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  if (parsed.orderBy) {
    parsed.orderBy = parseOrderBy(parsed.orderBy);
  }

  const [params, setParams] = useState(() => ({
    ...defaultParams,
    ...parsed,
  }));

  useEffect(() => {
    const processedParams = {
      ...params,
      orderBy: formatOrderBy(params.orderBy),
    };

    const newSearch = queryString.stringify(processedParams);
    if (location.search !== `?${newSearch}`) {
      history.push({ search: newSearch });
    }
  }, [params, location.search, history]);

  return [params, setParams];
}
