import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table";
import { useInView } from "react-intersection-observer";

import { useDebouncedValue } from "services/hooks/useDebouncedValue";

import { Spacer } from "../../../layouts/Spacer";
import { SearchTableInput } from "./SearchTableInput";
import { TableBody } from "./TableBody";
import { TableHeader } from "./TableHeader";
import { TopWrapper } from "./style";
import * as Styled from "../style";

export function InfiniteScrollTable({
  columns,
  isError,
  isLoading,
  children,
  sortingState,
  hasNextPage = false,
  isUpdatingData = false,
  data = [],
  loadMoreRows = () => {},
  onTableSearch = () => {},
}) {
  const { t } = useTranslation();
  const isLoadingData = isLoading && data?.length === 0;
  const isNoData = !data?.length;
  const tableContainerRef = useRef(null);

  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    setExpanded({});
  }, [sortingState]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      expanded,
      sorting: sortingState,
    },
    getRowCanExpand: (row) => row.depth === 0,
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.children,
    manualSorting: true,
  });

  const { ref: lastRowRef, inView: lastRowInView } = useInView({
    threshold: 0.5,
  });

  const debounceLastRowInView = useDebouncedValue(lastRowInView, 200);

  useEffect(() => {
    if (!isUpdatingData && debounceLastRowInView && hasNextPage) {
      loadMoreRows();
    }
  }, [debounceLastRowInView, isUpdatingData, hasNextPage, loadMoreRows]);

  return (
    <Styled.TableWrapper>
      <TopWrapper>
        <SearchTableInput onTableSearch={onTableSearch} />
        {children}
      </TopWrapper>
      <Spacer space={{ desktop: 10, tablet: 10, mobile: 10 }} />

      <Styled.GridTableContainer ref={tableContainerRef}>
        {isLoadingData ? (
          <Styled.ErrorText data-testid="loader">{t("Data is loading...")}</Styled.ErrorText>
        ) : isError ? (
          <Styled.ErrorText>{t("Error connecting to server")}.</Styled.ErrorText>
        ) : isNoData ? (
          <Styled.ErrorText>{t("No results.")}</Styled.ErrorText>
        ) : (
          <Styled.GridTable>
            <TableHeader table={table} />
            <TableBody table={table} lastRowRef={lastRowRef} tableContainerRef={tableContainerRef} />
          </Styled.GridTable>
        )}
      </Styled.GridTableContainer>
    </Styled.TableWrapper>
  );
}
