import basicConfig from "api/basicConfig";

import { formatOrderBy } from "../utils/formatOrderBy";

export const parcelsApi = {
  getParcels: ({ id, limit, page, params }) => {
    const queryParams = {
      limit,
      page,
      ...params,
      orderBy: params?.orderBy ? formatOrderBy(params?.orderBy) : undefined,
    };
    return basicConfig
      .createRequest(`/v3/portfolios/${id}/parcels`, "GET", {}, null, false, queryParams)
      .then((res) => res.data);
  },

  getParcelImage: ({ parcelId, signal }) => {
    return basicConfig
      .createRequest(`/v3/geolookup/image/parcel/${parcelId}`, "POST", {}, undefined, false, undefined, false, signal)
      .then((res) => res.data);
  },
};
