import Papa from "papaparse";

export async function formatCityOptions(subdivision, setCityOptions, country = { value: "us", subdivision: "state" }) {
  const cities =
    {
      us: "cities",
      jp: "japanese_cities",
    }[country.value] || "cities";

  Papa.parse(process.env.PUBLIC_URL + `/assets/csv/${cities}.csv`, {
    skipEmptyLines: true,
    delimiter: ",",
    header: true,
    download: true,
    complete: (result) => {
      setCityOptions(
        result.data
          .filter((city) => city[country?.subdivision] === subdivision?.value)
          .map((item) => ({ value: item.city, label: item.city, lat: item?.lat ?? "", lng: item?.lng ?? "" }))
      );
    },
  });
}
