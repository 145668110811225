export function formatOrderBy(orderBy) {
  if (orderBy && typeof orderBy === "object") {
    return Object.entries(orderBy)
      .map(([key, value]) => `${key}:${value}`)
      .join(",");
  }
  return orderBy;
}

export function parseOrderBy(orderByStr) {
  if (typeof orderByStr === "string") {
    return orderByStr.split(",").reduce((acc, pair) => {
      const [key, value] = pair.split(":");
      if (key && value) acc[key] = value;
      return acc;
    }, {});
  }
  return orderByStr;
}
