import React, { CSSProperties } from "react";
import * as AlertDialogRadix from "@radix-ui/react-alert-dialog";

import * as Styled from "./style";

export const AlertDialog = AlertDialogRadix.Root;

type AlertDialogTriggerProps = React.ComponentPropsWithoutRef<typeof AlertDialogRadix.Trigger>;

export const AlertDialogTrigger = React.forwardRef<HTMLButtonElement, AlertDialogTriggerProps>((props, ref) => (
  <AlertDialogRadix.Trigger asChild ref={ref} {...props} />
));
AlertDialogTrigger.displayName = AlertDialogRadix.Trigger.displayName;

type AlertDialogPortalProps = React.ComponentPropsWithoutRef<typeof AlertDialogRadix.Portal>;

function AlertDialogPortal(props: AlertDialogPortalProps) {
  return <AlertDialogRadix.Portal {...props} />;
}
AlertDialogPortal.displayName = AlertDialogRadix.Portal.displayName;

type AlertDialogOverlayProps = React.ComponentPropsWithoutRef<typeof Styled.Overlay> & {
  overlayColor?: string;
};

const AlertDialogOverlay = React.forwardRef<HTMLDivElement, AlertDialogOverlayProps>(
  ({ overlayColor, ...props }, ref) => {
    return <Styled.Overlay ref={ref} $overlayColor={overlayColor} {...props} />;
  }
);
AlertDialogOverlay.displayName = AlertDialogRadix.Overlay.displayName;

type AlertDialogContentProps = React.ComponentPropsWithoutRef<typeof Styled.Content> & {
  maxWidth?: number;
  overlayColor?: string;
  children: React.ReactNode;
};

export const AlertDialogContent = React.forwardRef<HTMLDivElement, AlertDialogContentProps>(
  ({ children, style, overlayColor, maxWidth = 600, ...props }, ref) => {
    return (
      <AlertDialogPortal>
        <AlertDialogOverlay overlayColor={overlayColor}>
          <Styled.Content ref={ref} style={{ "--max-width": `${maxWidth}px`, ...style } as CSSProperties} {...props}>
            {children}
          </Styled.Content>
        </AlertDialogOverlay>
      </AlertDialogPortal>
    );
  }
);
AlertDialogContent.displayName = AlertDialogRadix.Content.displayName;

type AlertDialogHeaderProps = React.ComponentPropsWithoutRef<typeof Styled.Header>;

export function AlertDialogHeader(props: AlertDialogHeaderProps) {
  return <Styled.Header {...props} />;
}
AlertDialogHeader.displayName = "AlertDialogHeader";

type AlertDialogFooterProps = React.ComponentPropsWithoutRef<typeof Styled.Footer>;

export function AlertDialogFooter(props: AlertDialogFooterProps) {
  return <Styled.Footer {...props} />;
}
AlertDialogFooter.displayName = "AlertDialogFooter";

type AlertDialogTitleProps = React.ComponentPropsWithoutRef<typeof Styled.Title>;

export const AlertDialogTitle = React.forwardRef<HTMLHeadingElement, AlertDialogTitleProps>((props, ref) => {
  return <Styled.Title ref={ref} {...props} />;
});
AlertDialogTitle.displayName = AlertDialogRadix.Title.displayName;

type AlertDialogDescriptionProps = React.ComponentPropsWithoutRef<typeof Styled.Description>;

export const AlertDialogDescription = React.forwardRef<HTMLParagraphElement, AlertDialogDescriptionProps>(
  (props, ref) => {
    return <Styled.Description ref={ref} {...props} />;
  }
);
AlertDialogDescription.displayName = AlertDialogRadix.Description.displayName;

type AlertDialogActionProps = React.ComponentPropsWithoutRef<typeof Styled.ActionButton>;

export const AlertDialogAction = React.forwardRef<HTMLButtonElement, AlertDialogActionProps>((props, ref) => {
  return (
    <AlertDialogRadix.Action asChild>
      <Styled.ActionButton ref={ref} {...props} />
    </AlertDialogRadix.Action>
  );
});
AlertDialogAction.displayName = AlertDialogRadix.Action.displayName;

type AlertDialogCancelProps = React.ComponentPropsWithoutRef<typeof Styled.CancelButton>;

export const AlertDialogCancel = React.forwardRef<HTMLButtonElement, AlertDialogCancelProps>((props, ref) => {
  return (
    <AlertDialogRadix.Cancel asChild>
      <Styled.CancelButton ref={ref} {...props} />
    </AlertDialogRadix.Cancel>
  );
});
AlertDialogCancel.displayName = AlertDialogRadix.Cancel.displayName;
