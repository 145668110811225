import styled from "styled-components/macro";

import { baseButtonStyle, COLORS } from "configs/styles/common";

export const CellWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const SortingButton = styled.button`
  ${baseButtonStyle};
  width: 30px;
  height: 24px;
  background-color: ${({ $isSorted }) => ($isSorted ? COLORS.ACCENT : COLORS.LIGHT.Slate)};
  border-radius: 5px;

  & svg {
    height: 10px;
    width: 11px;
  }
`;
